export enum FactoryType {
  "assembler",
  "mining machine",
  "chemical facility",
  "oil extraction facility",
  "particle collider",
  "refining facility",
  "research facility",
  "smelting facility",
  "water pumping facility",
}

export enum ProductId {
  "iron ore",
  "copper ore",
  "stone",
  "coal",
  "silicon ore",
  "titanium ore",
  "water",
  "crude oil",
  "hydrogen",
  "deuterium",
  "antimatter",
  "kimberlite ore",
  "iron ingot",
  "copper ingot",
  "stone brick",
  "energetic graphite",
  "high-purity silicon",
  "titanium ingot",
  "sulfuric acid",
  "refined oil",
  "hydrogen fuel rod",
  "deuteron fuel rod",
  "antimatter fuel rod",
  "fractal silicon",
  "magnet",
  "magnetic coil",
  "glass",
  "diamond",
  "diamond alt",
  "crystal silicon",
  "crystal silicon alt",
  "titanium alloy",
  "fire ice",
  "plastic",
  "organic crystal",
  "graphene",
  "graphene alt",
  "thruster",
  "optical grating crystal",
  "steel",
  "circuit board",
  "prism",
  "electric motor",
  "microcrystalline component",
  "casimir crystal",
  "casimir crystal alt",
  "strange matter",
  "titanium crystal",
  "carbon nanotube",
  "carbon nanotube alt",
  "reinforced thruster",
  "spiniform stalagmite crystal",
  "gear",
  "plasma exciter",
  "photon combiner",
  "photon combiner alt",
  "electromagnetic turbine",
  "processor",
  "annihilation constraint sphere",
  "titanium glass",
  "particle broadband",
  "logistics drone",
  "unipolar magnet",
  "foundation",
  "critical photon",
  "particle container",
  "particle container alt",
  "super-magnetic ring",
  "graviton lens",
  "space warper",
  "space warper alt",
  "plane filter",
  "quantum chip",
  "logistics vessel",
  "log",
  "electromagnetic matrix",
  "energy matrix",
  "structure matrix",
  "information matrix",
  "gravity matrix",
  "universe matrix",
  "solar sail",
  "frame material",
  "dyson sphere component",
  "small carrier rocket",
  "plant fuel",
  "tesla tower",
  "wireless power tower",
  "satellite substation",
  "wind turbine",
  "thermal power plant",
  "solar panel",
  "accumulator",
  "full accumulator",
  "ray receiver",
  "mini fusion power plant",
  "energy exchanger",
  "artificial star",
  "conveyor belt mk.i",
  "conveyor belt mk.ii",
  "conveyor belt mk.iii",
  "splitter",
  "storage mk.i",
  "storage mk.ii",
  "storage tank",
  "em-rail ejector",
  "planetary logistics station",
  "miniature particle collider",
  "sorter mk.i",
  "sorter mk.ii",
  "sorter mk.iii",
  "mining machine",
  "water pump",
  "oil extractor",
  "oil refinery",
  "vertical launching silo",
  "interstellar logistics station",
  "assembling machine mk.i",
  "assembling machine mk.ii",
  "assembling machine mk.iii",
  "arc smelter",
  "plane smelter",
  "fractionator",
  "chemical plant",
  "matrix lab",
  "orbital collector",
  "plasma refining",
  "deuterium fractionation",
  "x ray cracking",
  "organic crystal alt",
  "proliferator mk.i",
  "proliferator mk.ii",
  "proliferator mk.iii",
  "geothermal power station",
  "advanced mining machine",
  "automatic piler",
  "traffic monitor",
  "spray coater",
  "mass-energy storage",
}

export const screen = {
  desktop: "768px",
};
