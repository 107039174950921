import axios, { AxiosInstance } from "axios";

export class ServiceBase {
  accessToken: string;
  axiosInstance: AxiosInstance;
  constructor(accessToken: string) {
    this.accessToken = accessToken;
    this.axiosInstance = axios.create({
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  }

  requestConfig = () => {
    return {
      headers: {
        Authorization: `Bearer ${this.accessToken}`,
      },
    };
  };
}
