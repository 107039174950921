import * as React from "react";

interface IProps {
  icon: JSX.Element;
  label: string;
  quantity: number;

  onClick: () => void;
}

export default function Material(props: IProps) {
  return (
    <div
      style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
      onClick={props.onClick}
    >
      <div style={{ width: "10%" }}>{props.quantity}</div>
      <div style={{ padding: "0 5px" }}>{props.icon}</div>
      <div>{props.label}</div>
    </div>
  );
}
