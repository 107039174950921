import { GridIndex } from "../classes";
import { ProductId, FactoryType } from "../constants";
import { IProduct } from "../interfaces";

export const buildings: IProduct[] = [
  {
    id: ProductId["tesla tower"],
    img: "https://dsp-wiki.com/images/thumb/f/f1/Icon_Tesla_Tower.png/45px-Icon_Tesla_Tower.png",
    gridIndex: new GridIndex(0, 0),
    stack: 100,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 2,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["wireless power tower"],
    img: "https://dsp-wiki.com/images/thumb/c/c7/Icon_Wireless_Power_Tower.png/45px-Icon_Wireless_Power_Tower.png",
    gridIndex: new GridIndex(0, 1),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["tesla tower"],
            quantity: 1,
          },
          {
            productId: ProductId["plasma exciter"],
            quantity: 3,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["satellite substation"],
    img: "https://dsp-wiki.com/images/thumb/d/d7/Icon_Satellite_Substation.png/45px-Icon_Satellite_Substation.png",
    gridIndex: new GridIndex(0, 2),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["wireless power tower"],
            quantity: 1,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 10,
          },
          {
            productId: ProductId["frame material"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["wind turbine"],
    img: "https://dsp-wiki.com/images/thumb/1/14/Icon_Wind_Turbine.png/25px-Icon_Wind_Turbine.png",
    gridIndex: new GridIndex(0, 3),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 6,
          },
          {
            productId: ProductId["gear"],
            quantity: 1,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 3,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["thermal power plant"],
    img: "https://dsp-wiki.com/images/thumb/c/c5/Icon_Thermal_Power_Plant.png/45px-Icon_Thermal_Power_Plant.png",
    gridIndex: new GridIndex(0, 4),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 10,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 4,
          },
          {
            productId: ProductId["gear"],
            quantity: 4,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["solar panel"],
    img: "https://dsp-wiki.com/images/thumb/7/77/Icon_Solar_Panel.png/45px-Icon_Solar_Panel.png",
    gridIndex: new GridIndex(0, 5),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["copper ingot"],
            quantity: 10,
          },
          {
            productId: ProductId["high-purity silicon"],
            quantity: 10,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 5,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["accumulator"],
    img: "https://dsp-wiki.com/images/thumb/7/71/Icon_Accumulator.png/33px-Icon_Accumulator.png",
    gridIndex: new GridIndex(0, 6),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 6,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 1,
          },
          {
            productId: ProductId["crystal silicon"],
            quantity: 6,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["full accumulator"],
    img: "https://dsp-wiki.com/images/thumb/6/67/Icon_Full_Accumulator.png/45px-Icon_Full_Accumulator.png",

    production: [
      {
        materials: [
          {
            productId: ProductId["accumulator"],
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["ray receiver"],
    img: "https://dsp-wiki.com/images/thumb/7/7b/Icon_Ray_Receiver.png/45px-Icon_Ray_Receiver.png",
    gridIndex: new GridIndex(0, 10),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 20,
          },
          {
            productId: ProductId["high-purity silicon"],
            quantity: 20,
          },
          {
            productId: ProductId["photon combiner"],
            quantity: 10,
          },
          {
            productId: ProductId["processor"],
            quantity: 5,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 20,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["mini fusion power plant"],
    img: "https://dsp-wiki.com/images/thumb/0/03/Icon_Mini_Fusion_Power_Plant.png/45px-Icon_Mini_Fusion_Power_Plant.png",
    gridIndex: new GridIndex(0, 8),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 12,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 10,
          },
          {
            productId: ProductId["carbon nanotube"],
            quantity: 8,
          },
          {
            productId: ProductId["processor"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["energy exchanger"],
    img: "https://dsp-wiki.com/images/thumb/6/64/Icon_Energy_Exchanger.png/45px-Icon_Energy_Exchanger.png",
    gridIndex: new GridIndex(0, 9),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 40,
          },
          {
            productId: ProductId["steel"],
            quantity: 40,
          },
          {
            productId: ProductId["processor"],
            quantity: 40,
          },
          {
            productId: ProductId["particle container"],
            quantity: 8,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["artificial star"],
    img: "https://dsp-wiki.com/images/thumb/b/b6/Icon_Artificial_Star.png/45px-Icon_Artificial_Star.png",
    gridIndex: new GridIndex(0, 11),
    stack: 20,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 20,
          },
          {
            productId: ProductId["frame material"],
            quantity: 20,
          },
          {
            productId: ProductId["annihilation constraint sphere"],
            quantity: 10,
          },
          {
            productId: ProductId["quantum chip"],
            quantity: 10,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["conveyor belt mk.i"],
    img: "https://dsp-wiki.com/images/thumb/7/78/Icon_Conveyor_Belt_Mk.I.png/45px-Icon_Conveyor_Belt_Mk.I.png",
    gridIndex: new GridIndex(1, 0),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 2,
          },
          {
            productId: ProductId["gear"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["conveyor belt mk.ii"],
    img: "https://dsp-wiki.com/images/thumb/3/38/Icon_Conveyor_Belt_Mk.II.png/45px-Icon_Conveyor_Belt_Mk.II.png",
    gridIndex: new GridIndex(1, 1),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["conveyor belt mk.i"],
            quantity: 3,
          },
          {
            productId: ProductId["electromagnetic turbine"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["conveyor belt mk.iii"],
    img: "https://dsp-wiki.com/images/thumb/1/18/Icon_Conveyor_Belt_Mk.III.png/45px-Icon_Conveyor_Belt_Mk.III.png",
    gridIndex: new GridIndex(1, 2),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["conveyor belt mk.ii"],
            quantity: 3,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 1,
          },
          {
            productId: ProductId["graphene"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["splitter"],
    img: "https://dsp-wiki.com/images/thumb/d/d6/Icon_Splitter.png/42px-Icon_Splitter.png",
    gridIndex: new GridIndex(1, 3),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 3,
          },
          {
            productId: ProductId["gear"],
            quantity: 2,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["storage mk.i"],
    img: "https://dsp-wiki.com/images/thumb/6/68/Icon_Storage_Mk.I.png/45px-Icon_Storage_Mk.I.png",
    gridIndex: new GridIndex(1, 6),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 4,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["storage mk.ii"],
    img: "https://dsp-wiki.com/images/thumb/e/e2/Icon_Storage_Mk.II.png/45px-Icon_Storage_Mk.II.png",
    gridIndex: new GridIndex(1, 7),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 8,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 8,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["storage tank"],
    img: "https://dsp-wiki.com/images/thumb/0/00/Icon_Storage_Tank.png/45px-Icon_Storage_Tank.png",
    gridIndex: new GridIndex(1, 8),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 8,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 4,
          },
          {
            productId: ProductId["glass"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["em-rail ejector"],
    img: "https://dsp-wiki.com/images/thumb/a/a2/Icon_EM-Rail_Ejector.png/45px-Icon_EM-Rail_Ejector.png",
    gridIndex: new GridIndex(2, 10),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 20,
          },
          {
            productId: ProductId["gear"],
            quantity: 20,
          },
          {
            productId: ProductId["processor"],
            quantity: 5,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 10,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["planetary logistics station"],
    img: "https://dsp-wiki.com/images/thumb/e/e5/Icon_Planetary_Logistics_Station.png/31px-Icon_Planetary_Logistics_Station.png",
    gridIndex: new GridIndex(1, 9),
    stack: 10,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 40,
          },
          {
            productId: ProductId["titanium ingot"],
            quantity: 40,
          },
          {
            productId: ProductId["processor"],
            quantity: 40,
          },
          {
            productId: ProductId["particle container"],
            quantity: 20,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["miniature particle collider"],
    img: "https://dsp-wiki.com/images/thumb/a/aa/Icon_Miniature_Particle_Collider.png/45px-Icon_Miniature_Particle_Collider.png",
    gridIndex: new GridIndex(2, 9),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 20,
          },
          {
            productId: ProductId["frame material"],
            quantity: 20,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 50,
          },
          {
            productId: ProductId["graphene"],
            quantity: 10,
          },
          {
            productId: ProductId["processor"],
            quantity: 8,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["sorter mk.i"],
    img: "https://dsp-wiki.com/images/thumb/4/4f/Icon_Sorter_Mk.I.png/45px-Icon_Sorter_Mk.I.png",
    gridIndex: new GridIndex(2, 0),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 1,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["sorter mk.ii"],
    img: "https://dsp-wiki.com/images/thumb/2/25/Icon_Sorter_Mk.II.png/45px-Icon_Sorter_Mk.II.png",
    gridIndex: new GridIndex(2, 1),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["sorter mk.i"],
            quantity: 2,
          },
          {
            productId: ProductId["electric motor"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["sorter mk.iii"],
    img: "https://dsp-wiki.com/images/thumb/b/b5/Icon_Sorter_Mk.III.png/45px-Icon_Sorter_Mk.III.png",
    gridIndex: new GridIndex(2, 2),
    stack: 200,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["sorter mk.ii"],
            quantity: 2,
          },
          {
            productId: ProductId["electromagnetic turbine"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["mining machine"],
    type: FactoryType["mining machine"],
    img: "https://dsp-wiki.com/images/thumb/c/cf/Icon_Mining_Machine.png/45px-Icon_Mining_Machine.png",
    gridIndex: new GridIndex(2, 3),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 4,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 2,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 2,
          },
          {
            productId: ProductId["gear"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["water pump"],
    type: FactoryType["mining machine"],
    img: "https://dsp-wiki.com/images/thumb/6/69/Icon_Water_Pump.png/45px-Icon_Water_Pump.png",
    gridIndex: new GridIndex(2, 5),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 8,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 4,
          },
          {
            productId: ProductId["electric motor"],
            quantity: 4,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["oil extractor"],
    type: FactoryType["oil extraction facility"],
    img: "https://dsp-wiki.com/images/thumb/8/8f/Icon_Oil_Extractor.png/34px-Icon_Oil_Extractor.png",
    gridIndex: new GridIndex(2, 6),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 12,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 12,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 6,
          },
          {
            productId: ProductId["plasma exciter"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["oil refinery"],
    img: "https://dsp-wiki.com/images/thumb/7/74/Icon_Oil_Refinery.png/44px-Icon_Oil_Refinery.png",
    gridIndex: new GridIndex(2, 7),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 10,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 10,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 6,
          },
          {
            productId: ProductId["plasma exciter"],
            quantity: 6,
          },
        ],
        rates: [45, 60, 90],
      },
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["crude oil"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["refined oil"],
            quantity: 1,
          },
          {
            productId: ProductId["hydrogen"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["vertical launching silo"],
    img: "https://dsp-wiki.com/images/thumb/0/05/Icon_Vertical_Launching_Silo.png/45px-Icon_Vertical_Launching_Silo.png",
    gridIndex: new GridIndex(2, 11),
    stack: 10,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 80,
          },
          {
            productId: ProductId["frame material"],
            quantity: 30,
          },
          {
            productId: ProductId["graviton lens"],
            quantity: 20,
          },
          {
            productId: ProductId["quantum chip"],
            quantity: 10,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["interstellar logistics station"],
    img: "https://dsp-wiki.com/images/thumb/6/6c/Icon_Interstellar_Logistics_Station.png/38px-Icon_Interstellar_Logistics_Station.png",
    gridIndex: new GridIndex(1, 10),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["planetary logistics station"],
            quantity: 1,
          },
          {
            productId: ProductId["titanium alloy"],
            quantity: 50,
          },
          {
            productId: ProductId["particle container"],
            quantity: 20,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["assembling machine mk.i"],
    type: FactoryType["assembler"],
    img: "https://dsp-wiki.com/images/thumb/8/8e/Icon_Assembling_Machine_Mk.I.png/43px-Icon_Assembling_Machine_Mk.I.png",
    gridIndex: new GridIndex(3, 0),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 4,
          },
          {
            productId: ProductId["gear"],
            quantity: 8,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["assembling machine mk.ii"],
    img: "https://dsp-wiki.com/images/thumb/9/93/Icon_Assembling_Machine_Mk.II.png/43px-Icon_Assembling_Machine_Mk.II.png",
    gridIndex: new GridIndex(3, 1),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["assembling machine mk.i"],
            quantity: 1,
          },
          {
            productId: ProductId["graphene"],
            quantity: 8,
          },
          {
            productId: ProductId["processor"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["assembling machine mk.iii"],
    img: "https://dsp-wiki.com/images/thumb/8/8c/Icon_Assembling_Machine_Mk.III.png/45px-Icon_Assembling_Machine_Mk.III.png",
    gridIndex: new GridIndex(3, 2),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["assembling machine mk.ii"],
            quantity: 1,
          },
          {
            productId: ProductId["particle broadband"],
            quantity: 8,
          },
          {
            productId: ProductId["quantum chip"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["arc smelter"],
    type: FactoryType["smelting facility"],
    img: "https://dsp-wiki.com/images/thumb/6/60/Icon_Arc_Smelter.png/45px-Icon_Arc_Smelter.png",
    gridIndex: new GridIndex(3, 3),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 4,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 2,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 4,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["plane smelter"],
    img: "https://dsp-wiki.com/images/thumb/1/13/Icon_Plane_Smelter.png/45px-Icon_Plane_Smelter.png",
    gridIndex: new GridIndex(3, 4),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["arc smelter"],
            quantity: 1,
          },
          {
            productId: ProductId["frame material"],
            quantity: 5,
          },
          {
            productId: ProductId["plane filter"],
            quantity: 4,
          },
          {
            productId: ProductId["unipolar magnet"],
            quantity: 15,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["fractionator"],
    img: "https://dsp-wiki.com/images/thumb/2/28/Icon_Fractionator.png/35px-Icon_Fractionator.png",
    gridIndex: new GridIndex(2, 8),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 8,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 4,
          },
          {
            productId: ProductId["glass"],
            quantity: 4,
          },
          {
            productId: ProductId["processor"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["chemical plant"],
    img: "https://dsp-wiki.com/images/thumb/f/f9/Icon_Chemical_Plant.png/45px-Icon_Chemical_Plant.png",
    gridIndex: new GridIndex(3, 6),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 8,
          },
          {
            productId: ProductId["stone brick"],
            quantity: 8,
          },
          {
            productId: ProductId["glass"],
            quantity: 8,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["matrix lab"],
    img: "https://dsp-wiki.com/images/thumb/b/b7/Icon_Matrix_Lab.png/45px-Icon_Matrix_Lab.png",
    gridIndex: new GridIndex(3, 7),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 8,
          },
          {
            productId: ProductId["glass"],
            quantity: 4,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 4,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 4,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["orbital collector"],
    img: "https://dsp-wiki.com/images/thumb/6/64/Icon_Orbital_Collector.png/42px-Icon_Orbital_Collector.png",
    gridIndex: new GridIndex(1, 11),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["interstellar logistics station"],
            quantity: 1,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 50,
          },
          {
            productId: ProductId["reinforced thruster"],
            quantity: 20,
          },
          {
            productId: ProductId["full accumulator"],
            quantity: 20,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["geothermal power station"],
    img: "img/buildings/geothermal-power-station.png",
    gridIndex: new GridIndex(0, 7),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 15,
          },
          {
            productId: ProductId["copper ingot"],
            quantity: 20,
          },
          {
            productId: ProductId["photon combiner"],
            quantity: 4,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["advanced mining machine"],
    img: "img/buildings/advanced-mining-machine.png",
    gridIndex: new GridIndex(2, 4),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 20,
          },
          {
            productId: ProductId["frame material"],
            quantity: 10,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 10,
          },
          {
            productId: ProductId["quantum chip"],
            quantity: 4,
          },
          {
            productId: ProductId["optical grating crystal"],
            quantity: 40,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["automatic piler"],
    img: "img/buildings/automatic-piler.png",
    gridIndex: new GridIndex(1, 4),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 3,
          },
          {
            productId: ProductId["gear"],
            quantity: 4,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 1,
          },
          {
            productId: ProductId["processor"],
            quantity: 2,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["traffic monitor"],
    img: "img/buildings/traffic-monitor.png",
    gridIndex: new GridIndex(1, 5),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 3,
          },
          {
            productId: ProductId["gear"],
            quantity: 2,
          },
          {
            productId: ProductId["glass"],
            quantity: 1,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 2,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["spray coater"],
    img: "img/buildings/spray-coater.png",
    gridIndex: new GridIndex(3, 5),
    stack: 50,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 4,
          },
          {
            productId: ProductId["plasma exciter"],
            quantity: 2,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 2,
          },
          {
            productId: ProductId["microcrystalline component"],
            quantity: 2,
          },
        ],
      },
    ],
  },
];
