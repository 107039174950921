import * as React from "react";
import styled from "styled-components";
import EditableLabel from "../../../components/EditableLabel";

import { IStar } from "../../../utils/interfaces";
import { useService } from "../ServiceProvider";

interface IProps {
  star: IStar;
}

const Container = styled.div``;
const EltContainer = styled.div`
  display: flex;
`;

export default function StarInfo(props: IProps) {
  const [star, setStar] = React.useState(props.star);

  const { starService } = useService();

  const handleUpdate = (star: IStar) => {
    starService?.updateStar(star);
    setStar(star);
  };

  return (
    <Container>
      <EltContainer>
        <div>radius: </div>
        <EditableLabel
          text={star.radius ? star.radius.toString() : "nc"}
          editButtonOnHover
          onValidation={(input) => {
            const newStar = Object.assign({}, star, { radius: input });
            handleUpdate(newStar);
          }}
        />
      </EltContainer>
      <EltContainer>
        <div>luminosity: </div>
        <EditableLabel
          text={star.luminosity ? star.luminosity.toString() : "nc"}
          editButtonOnHover
          onValidation={(input) => {
            const newStar = Object.assign({}, star, { luminosity: input });
            handleUpdate(newStar);
          }}
        />
      </EltContainer>
      <EltContainer>
        <div>temperature: </div>
        <EditableLabel
          text={star.temperature ? star.temperature.toString() : "nc"}
          editButtonOnHover
          onValidation={(input) => {
            const newStar = Object.assign({}, star, { temperature: input });
            handleUpdate(newStar);
          }}
        />
      </EltContainer>
    </Container>
  );
}
