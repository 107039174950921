class AuthentRequiredServiceFactory {
  accessToken: string;
  constructor(accessToken: string) {
    this.accessToken = accessToken;
  }

  getInstance = (service: any) => {
    return new service(this.accessToken);
  };
}

export const authentRequiredServiceFactory = (accessToken: string) =>
  new AuthentRequiredServiceFactory(accessToken);
