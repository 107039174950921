import * as React from "react";
import styled from "styled-components";

import { IPlanet, IProduct } from "../../utils/interfaces";
import { useTranslation } from "react-i18next";

import Products from "../../components/Products";

interface IProps {
  planet: IPlanet;
  planetProducts: IProduct[];
  selectedProduct?: IProduct;
  enabledButton: "add" | "remove";

  onAddClick: () => void;
  onRemoveClick: () => void;
  onProductClick: (product: IProduct) => void;
  onProductDblClick: (product: IProduct) => void;
}

const Container = styled.div``;

export default function PlanetDisplay(props: IProps) {
  const { t } = useTranslation();

  const handleAddProduct = () => {
    if (props.selectedProduct === undefined) return;
    props.onAddClick();
  };

  const handleRemoveProduct = () => {
    if (props.selectedProduct === undefined) return;
    props.onRemoveClick();
  };

  return (
    <Container>
      {props.planet.name}
      {props.planetProducts.length > 0 && (
        <>
          <Products
            elements={props.planetProducts}
            onClick={(product) => props.onProductClick(product)}
            onDoubleClick={(product) => props.onProductDblClick(product)}
          />
        </>
      )}
      <hr />

      {props.selectedProduct !== undefined && (
        <>
          <button onClick={handleAddProduct} disabled={props.enabledButton !== "add"}>
            {t("dsp:label.addProduct")}
          </button>
          <button onClick={handleRemoveProduct} disabled={props.enabledButton !== "remove"}>
            {t("dsp:label.removeProduct")}
          </button>
        </>
      )}
    </Container>
  );
}
