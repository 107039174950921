import Products from "../../components/Products";
import { products } from "../../utils/data";
import { IProduct } from "../../utils/interfaces";

interface IProps {
  selectedProduct: IProduct | undefined;
}

export default function MaterialUsedIn({ selectedProduct }: IProps) {
  if (!selectedProduct) return null;

  const usedIn: IProduct[] = products.filter((p: IProduct) => p.production[0]?.materials?.map((m) => m.productId).includes(selectedProduct.id));

  if (usedIn.length === 0) return null;

  return (
    <>
      <div id="MaterialUsedIn">
        Used in {usedIn.length} product(s)
        <Products elements={usedIn} />
      </div>
      <hr />
    </>
  );
}
