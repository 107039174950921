import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import { Tab, Tabs, TextField } from "@material-ui/core";

import { IPlanet } from "../../utils/interfaces";
import { usePageContext } from "./ContextProvider";
import { useService } from "./ServiceProvider";

import EditableLabel from "../../components/EditableLabel";
import { routes } from "../../utils/routes";

interface IProps {
  starId: number;
  // elements: T[];
  // selectedElement?: T;

  // labelFunc: (elt: T) => string;

  // onAdd: (name: string) => void;
  // onPlanetClick: (planet: T) => void;
  // onChange: (planet: T, newName: string) => void;
}

const Container = styled.div``;

const LabelContainer = styled.div`
  width: 100%;
  display: inline-flex;
`;

const DelIconContainer = styled.div`
  display: none;
  ${LabelContainer}:hover & {
    display: inline;
  }
`;

export default function PlanetsManager(props: IProps) {
  const [planets, setPlanets] = useState([] as IPlanet[]);
  const [input, setInput] = useState("");

  const navigate = useNavigate();
  const { planetService } = useService();
  const context = usePageContext();

  useEffect(() => {
    (async () => {
      const planets = await planetService?.getAllFromStar(props.starId);
      if (planets === undefined) return;
      setPlanets(planets);
    })();
  }, [planetService, props.starId]);

  const handleAdd = async () => {
    if (input === undefined || input === "") return;
    const newPlanet = await planetService?.add(props.starId, input);
    setInput("");
    if (newPlanet === undefined) return;
    setPlanets([...planets, newPlanet]);
  };

  const handleUpdate = (planet: IPlanet, newName: string) => {
    if (newName === undefined || newName === "") return;

    planetService?.updatePlanet(planet, newName);
    const updatedPlanet = Object.assign({}, planet, { name: newName }) as IPlanet;
    const index = planets.indexOf(planet);
    if (index !== -1) {
      planets.splice(index, 1, updatedPlanet);
      setPlanets([...planets]);
    }

    if (context.setSelectedPlanet === undefined) return;
    context.setSelectedPlanet(updatedPlanet);
  };

  const handleTabChange = (e: any, value: IPlanet) => {
    if (planets.indexOf(value) === -1) return;
    if (context.setSelectedPlanet === undefined) return;
    context.setSelectedPlanet(value);
    navigate(routes.organizer);
  };

  const handleDelete = (planet: IPlanet) => {
    if (planet.id === undefined) return;
    planetService?.delete(planet.id);

    const index = planets.indexOf(planet);
    if (index !== -1) {
      planets.splice(index, 1);
      setPlanets([...planets]);
      if (context.setSelectedPlanet === undefined) return;
      context.setSelectedPlanet(undefined);
    }
  };

  return (
    <Container>
      <TextField
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => {
          if (e.key !== "Enter") return;
          handleAdd();
        }}
      />
      <button onClick={() => handleAdd()}>add planet</button>
      <Tabs
        value={context.selectedPlanet}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        orientation="vertical"
        // scrollButtons="auto"
      >
        {planets.map((planet) => (
          <Tab
            key={planet.id}
            label={
              <LabelContainer>
                <EditableLabel
                  text={planet.name}
                  onValidation={(input) => handleUpdate(planet, input)}
                />
                <DelIconContainer onClick={() => handleDelete(planet)}>x</DelIconContainer>
              </LabelContainer>
            }
            value={planet}
          />
        ))}
      </Tabs>
    </Container>
  );
}
