// localization : todo i18next files for each language
import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import { resources } from "@gamate/common";
import { header } from "@gamate/components/i18n";

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: 'en',
        fallbackLng: 'en',

        // keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        },

        parseMissingKeyHandler: (key) => { return undefined }
    });

const dspResources = {
    label: {
        addProduct: "add to prod",
        addStar: "add star",
        removeProduct: "remove from prod",
    }
}

i18n.addResourceBundle('en', 'dsp', dspResources);
i18n.addResourceBundle('en', 'header', header.en);

export default i18n;