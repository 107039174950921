export const config = {
  API_URL: process.env.REACT_APP_API_URL as string,
  AUTH_DOMAIN: process.env.REACT_APP_AUTH_DOMAIN as string,
  AUTH_CLIENTID: process.env.REACT_APP_AUTH_CLIENTID as string,
  AUTH_SCOPE: process.env.REACT_APP_AUTH_SCOPE as string,

  authTokenAudience: process.env.REACT_APP_AUTH_TOKEN_AUDIENCE as string,
  mainSiteHost: process.env.REACT_APP_MAIN_SITE_HOST as string,
};

