import { ProductId, FactoryType } from "../constants";
import { IProduct } from "../interfaces";

export const resources: IProduct[] = [
  {
    id: ProductId["iron ore"],
    img: "https://dsp-wiki.com/images/thumb/f/fc/Icon_Iron_Ore.png/42px-Icon_Iron_Ore.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["copper ore"],
    img: "https://dsp-wiki.com/images/thumb/9/90/Icon_Copper_Ore.png/45px-Icon_Copper_Ore.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["stone"],
    img: "https://dsp-wiki.com/images/thumb/5/5a/Icon_Stone.png/45px-Icon_Stone.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["coal"],
    img: "https://dsp-wiki.com/images/thumb/a/a2/Icon_Coal.png/45px-Icon_Coal.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["silicon ore"],
    img: "https://dsp-wiki.com/images/thumb/d/d4/Icon_Silicon_Ore.png/44px-Icon_Silicon_Ore.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["titanium ore"],
    img: "https://dsp-wiki.com/images/thumb/9/91/Icon_Titanium_Ore.png/44px-Icon_Titanium_Ore.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["water"],
    img: "https://dsp-wiki.com/images/thumb/6/65/Icon_Water.png/25px-Icon_Water.png",
    production: [
      {
        factoryType: FactoryType["water pumping facility"],
        rates: [50],
      },
    ],
  },
  {
    id: ProductId["crude oil"],
    img: "https://dsp-wiki.com/images/thumb/8/8f/Icon_Crude_Oil.png/27px-Icon_Crude_Oil.png",
    production: [
      {
        factoryType: FactoryType["oil extraction facility"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["hydrogen"],
    img: "https://dsp-wiki.com/images/thumb/4/42/Icon_Hydrogen.png/45px-Icon_Hydrogen.png",
    production: [],
  },
  {
    id: ProductId["deuterium"],
    img: "https://dsp-wiki.com/images/thumb/4/4d/Icon_Deuterium.png/45px-Icon_Deuterium.png",
    production: [],
  },
  {
    id: ProductId["kimberlite ore"],
    img: "https://dsp-wiki.com/images/thumb/5/55/Icon_Kimberlite_Ore.png/44px-Icon_Kimberlite_Ore.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["fractal silicon"],
    img: "https://dsp-wiki.com/images/thumb/3/35/Icon_Fractal_Silicon.png/44px-Icon_Fractal_Silicon.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["optical grating crystal"],
    img: "https://dsp-wiki.com/images/thumb/a/ac/Icon_Optical_Grating_Crystal.png/37px-Icon_Optical_Grating_Crystal.png",
    production: [{ factoryType: FactoryType["mining machine"], rates: [30] }],
  },
  {
    id: ProductId["spiniform stalagmite crystal"],
    img: "https://dsp-wiki.com/images/thumb/b/bd/Icon_Spiniform_Stalagmite_Crystal.png/45px-Icon_Spiniform_Stalagmite_Crystal.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["unipolar magnet"],
    img: "https://dsp-wiki.com/images/thumb/a/a9/Icon_Unipolar_Magnet.png/45px-Icon_Unipolar_Magnet.png",
    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["log"],
    img: "https://dsp-wiki.com/images/thumb/4/4a/Icon_Log.png/45px-Icon_Log.png",
    production: [],
  },
  {
    id: ProductId["plant fuel"],
    img: "https://dsp-wiki.com/images/thumb/6/6a/Icon_Plant_Fuel.png/45px-Icon_Plant_Fuel.png",
    production: [],
  },
  {
    id: ProductId["sulfuric acid"],
    img: "https://dsp-wiki.com/images/thumb/5/53/Icon_Sulfuric_Acid.png/25px-Icon_Sulfuric_Acid.png",
    production: [
      {
        factoryType: FactoryType["water pumping facility"],
        rates: [50],
      },
    ],
  },
  {
    id: ProductId["fire ice"],
    img: "https://dsp-wiki.com/images/thumb/a/ab/Icon_Fire_Ice.png/45px-Icon_Fire_Ice.png",
    production: [],
  },
  {
    id: ProductId["organic crystal"],
    img: "https://dsp-wiki.com/images/thumb/9/96/Icon_Organic_Crystal.png/30px-Icon_Organic_Crystal.png",

    production: [
      {
        factoryType: FactoryType["mining machine"],
        rates: [30],
      },
    ],
  },
];
