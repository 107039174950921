import { createContext, PropsWithChildren, useContext } from "react";
import { IPlanet } from "../../utils/interfaces";

interface IPageContextProps extends PropsWithChildren<any> {
  selectedPlanet?: IPlanet;
  setSelectedPlanet?: (planet: IPlanet | undefined) => void;
}

const PageContext = createContext({} as IPageContextProps);

export function PageContextProvider(props: IPageContextProps) {
  const value = {
    selectedPlanet: props.selectedPlanet,
    setSelectedPlanet: props.setSelectedPlanet,
  };

  return <PageContext.Provider value={value}>{props.children}</PageContext.Provider>;
}

export const usePageContext = () => useContext(PageContext);
