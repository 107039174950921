import * as React from "react";

import styled from "styled-components";

const Container = styled.div`
  height: 45px;
  width: 45px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

const Img = styled.img`
  max-height: 45px;
`;

interface IProps extends React.ComponentProps<any> {
  url?: string;
  alt: string;
}

export default function ProductIcon(props: IProps) {
  if (props.url === undefined) {
    return <Container {...props}>{props.alt}</Container>;
  }

  return (
    <Container {...props}>
      <Img src={props.url} alt={props.alt} />
    </Container>
  );
}
