import { useEffect, useRef, useState } from "react";
import { TextField, Typography } from "@material-ui/core";

import EditIcon from "@material-ui/icons/Edit";
import styled from "styled-components";

interface IProps {
  text: string;
  editButtonOnHover?: boolean;
  onEdit: (isEditing: boolean) => void;
  onValidation: (input: string) => void;
}

const LabelContainer = styled.div`
  width: 100%;
  display: inline-flex;
`;

const EditIconContainer = styled.div<any>`
  display: none;
  ${LabelContainer}:hover & {
    display: inline;
  }
`;

export default function EditableLabel({ text, editButtonOnHover, onEdit, onValidation }: IProps) {
  const [isEditing, setIsEditing] = useState(false);
  const [input, setInput] = useState(text);
  const refInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing) setInput(text);
  }, [isEditing, text]);

  useEffect(() => {
    if (refInput.current) refInput.current.focus();
  }, [isEditing, refInput]);

  useEffect(() => {
    onEdit(isEditing);
  }, [isEditing, onEdit]);

  return (
    <>
      {isEditing && (
        <TextField
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onBlur={() => {
            setIsEditing(false);
            setInput(text);
          }}
          onKeyPress={(e) => {
            if (e.key === "Enter") {
              onValidation(input);
              setIsEditing(false);
            }
          }}
          inputRef={refInput}
        />
      )}
      {!isEditing && (
        <LabelContainer onDoubleClick={() => setIsEditing(true)}>
          <Typography>{input}</Typography>
          {editButtonOnHover && (
            <EditIconContainer onClick={() => setIsEditing(true)}>
              <EditIcon />
            </EditIconContainer>
          )}
        </LabelContainer>
      )}
    </>
  );
}

EditableLabel.defaultProps = {
  onEdit: () => {},
};
