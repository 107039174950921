import { ProductId, screen } from "../utils/constants";
import { IProduct } from "../utils/interfaces";

import ProductIcon from "./ProductIcon";
import styled from "styled-components";

interface IProps {
  elements: IProduct[];
  selectedIds: ProductId[];

  onClick?: (product: IProduct) => void;
  onDoubleClick?: (product: IProduct) => void;
}

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: ${screen.desktop}) {
    overflow: scroll;
  }
  @media (min-width: ${screen.desktop}) {
    width: 645px;
  }
`;

export default function Products(props: IProps) {
  if (props.elements.length === 0) return null;
  return (
    <>
      <Container>
        {props.elements.map((component: IProduct, index: number) => (
          <div
            key={index}
            onClick={() => {
              if (props.onClick) props.onClick(component);
            }}
            onDoubleClick={() => {
              if (props.onDoubleClick) props.onDoubleClick(component);
            }}
          >
            <ProductIcon
              style={
                props.selectedIds.includes(component.id)
                  ? { border: "solid 1px" }
                  : { margin: "1px" }
              }
              url={component.img}
              alt={ProductId[component.id].toString()}
            />
          </div>
        ))}
      </Container>
    </>
  );
}

Products.defaultProps = {
  selectedIds: [],
};
