import { ProductId } from "../constants";
import { IProduct } from "../interfaces";
import { buildings as innerBuildings } from "./buildings";
import { items as innerItems } from "./items";
import { resources as innerResources } from "./resources";

const kvpFormat = (c: IProduct): [number, IProduct] => {
  let gridIndex: number;
  if (c.gridIndex !== undefined) {
    gridIndex = c.gridIndex.colIndex + c.gridIndex.rowIndex * 12;
  } else {
    gridIndex = -1;
  }
  return [gridIndex, c];
};

export const itemByGridOrder = new Map(innerItems.map(kvpFormat));
export const buildingByGridOrder = new Map(innerBuildings.map(kvpFormat));
export const resourceByGridOrder = new Map(innerResources.map(kvpFormat));

export const resources = innerResources;
export const items = innerItems;
export const buildings = innerBuildings;

export const products = [...resources, ...items, ...buildings];
export const productById = new Map<ProductId, IProduct>(
  products.map((p) => {
    return [p.id, p];
  })
);
