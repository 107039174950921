import { ProductId } from "../constants";
import { IPlanet } from "../interfaces";
import { apiUrls } from "../routes";
import { ServiceBase } from "./servicebase";

export interface IPlanetService {
  add: (starId: number, name: string) => Promise<IPlanet>;
  delete: (id: number) => void;
  getAllFromStar: (starId: number) => Promise<IPlanet[]>;
  getAllWithProduct: (productId: ProductId) => Promise<IPlanet[]>;
  updatePlanet: (planet: IPlanet, newName: string) => void;
}

export class PlanetService extends ServiceBase implements IPlanetService {
  getAllFromStar = async (starId: number) => {
    const result = await this.axiosInstance.get(`${apiUrls.planets}/star/${starId}`);
    return result.data;
  };
  getAllWithProduct = async (productId: ProductId) => {
    const result = await this.axiosInstance.get(`${apiUrls.planets}/product/${productId}`);
    return result.data;
  };
  add = async (starId: number, name: string) => {
    const result = await this.axiosInstance.post(`${apiUrls.planets}`, { starId, name });

    // if empty => '', 0, false, null, undefined
    if (!result.data) return undefined;
    return result.data;
  };

  delete = async (id: number) => {
    return await this.axiosInstance.delete(`${apiUrls.planets}/${id}`);
  };
  updatePlanet = async (planet: IPlanet, newName: string) => {
    return await this.axiosInstance.put(apiUrls.planets, { id: planet.id, name: newName });
  };
}
