import { useState } from "react";
import { Route, Routes } from "react-router-dom";
import styled from "styled-components";

import { useAuth } from "@gamate/common";
import { Link } from "@gamate/components";
import { ContentBlockElement, Layout, SideBarBlock } from "@gamate/components/layout";

import { ServiceProvider } from "./pages/PlanetOrganizer/ServiceProvider";
import { routes } from "./utils/routes";
import { screen } from "./utils/constants";
import InformationPage from "./pages/Information";
import PlanetOrganizer from "./pages/PlanetOrganizer";
import { config } from "./utils/config";
import StarsManager from "./pages/PlanetOrganizer/StarsManager";
import { IPlanet } from "./utils/interfaces";
import { PageContextProvider } from "./pages/PlanetOrganizer/ContextProvider";

const Container = styled.div`
  @media (min-width: ${screen.desktop}) {
    display: flex;
    justify-content: center;
  }
`;

export default function App() {
  const [selectedPlanet, setSelectedPlanet] = useState(undefined as IPlanet | undefined);

  const { isAuthenticated } = useAuth(config.authTokenAudience);
  return (
    <>
      <PageContextProvider selectedPlanet={selectedPlanet} setSelectedPlanet={setSelectedPlanet}>
        <ServiceProvider>
          <Layout
            authTokenAudience={config.authTokenAudience}
            mainSiteHost={config.mainSiteHost}
            leftSideContent={
              <>
                <SideBarBlock>
                  {!isAuthenticated && <>Log in to manage stars</>}
                  {isAuthenticated && <StarsManager />}
                </SideBarBlock>
                <SideBarBlock>
                  <Link to={routes.organizer} className="link">
                    <ContentBlockElement>Organizer</ContentBlockElement>
                  </Link>
                  <Link to={routes.info} className="link">
                    <ContentBlockElement>Info</ContentBlockElement>
                  </Link>
                </SideBarBlock>
              </>
            }
          >
            <Container className="page-content">
              <div>
                <Routes>
                  <Route path={routes.info} element={<InformationPage />} />
                  <Route path={"/"} element={<PlanetOrganizer />} />
                  <Route path={routes.organizer} element={<PlanetOrganizer />} />
                </Routes>
              </div>
            </Container>
          </Layout>
        </ServiceProvider>
      </PageContextProvider>
    </>
  );
}
