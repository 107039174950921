import { GridIndex } from "../classes";
import { ProductId, FactoryType } from "../constants";
import { IProduct } from "../interfaces";

export const items: IProduct[] = [
  {
    id: ProductId["silicon ore"],
    img: "https://dsp-wiki.com/images/thumb/d/d4/Icon_Silicon_Ore.png/44px-Icon_Silicon_Ore.png",
    gridIndex: new GridIndex(3, 2),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["stone"],
            quantity: 10,
          },
        ],
        rates: [6],
      },
    ],
  },
  {
    id: ProductId["deuterium"],
    img: "https://dsp-wiki.com/images/thumb/4/4d/Icon_Deuterium.png/45px-Icon_Deuterium.png",
    gridIndex: new GridIndex(3, 6),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["particle collider"],
        materials: [
          {
            productId: ProductId["hydrogen"],
            quantity: 10,
          },
        ],
        rates: [],
      },
      {
        factoryType: FactoryType["particle collider"],
        materials: [
          {
            productId: ProductId["hydrogen"],
            quantity: 1,
          },
        ],
        rates: [],
      },
    ],
  },
  {
    id: ProductId["antimatter"],
    img: "https://dsp-wiki.com/images/thumb/7/78/Icon_Antimatter.png/45px-Icon_Antimatter.png",
    production: [
      {
        factoryType: FactoryType["particle collider"],
        materials: [
          {
            productId: ProductId["critical photon"],
            quantity: 2,
          },
          {
            productId: ProductId["mass-energy storage"],
            quantity: 0,
          },
        ],
        rates: [],
      },
    ],
  },
  {
    id: ProductId["iron ingot"],
    img: "https://dsp-wiki.com/images/thumb/f/f1/Icon_Iron_Ingot.png/45px-Icon_Iron_Ingot.png",
    gridIndex: new GridIndex(0, 0),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["iron ore"],
            quantity: 1,
          },
        ],
        rates: [60],
      },
    ],
  },
  {
    id: ProductId["copper ingot"],
    img: "https://dsp-wiki.com/images/thumb/e/ec/Icon_Copper_Ingot.png/45px-Icon_Copper_Ingot.png",
    gridIndex: new GridIndex(0, 1),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["copper ore"],
            quantity: 1,
          },
        ],
        rates: [60],
      },
    ],
  },
  {
    id: ProductId["stone brick"],
    img: "https://dsp-wiki.com/images/thumb/6/61/Icon_Stone_Brick.png/45px-Icon_Stone_Brick.png",
    gridIndex: new GridIndex(0, 4),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["stone"],
            quantity: 1,
          },
        ],
        rates: [60],
      },
    ],
  },
  {
    id: ProductId["energetic graphite"],
    img: "https://dsp-wiki.com/images/thumb/1/11/Icon_Energetic_Graphite.png/39px-Icon_Energetic_Graphite.png",
    gridIndex: new GridIndex(0, 5),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["coal"],
            quantity: 2,
          },
        ],
        rates: [30],
      },
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["refined oil"],
            quantity: 1,
          },
          {
            productId: ProductId["hydrogen"],
            quantity: 2,
          },
        ],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["high-purity silicon"],
    img: "https://dsp-wiki.com/images/thumb/8/8a/Icon_High-Purity_Silicon.png/45px-Icon_High-Purity_Silicon.png",
    gridIndex: new GridIndex(0, 2),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["silicon ore"],
            quantity: 2,
          },
        ],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["titanium ingot"],
    img: "https://dsp-wiki.com/images/thumb/f/f1/Icon_Titanium_Ingot.png/45px-Icon_Titanium_Ingot.png",
    gridIndex: new GridIndex(0, 3),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["titanium ore"],
            quantity: 2,
          },
        ],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["sulfuric acid"],
    img: "https://dsp-wiki.com/images/thumb/5/53/Icon_Sulfuric_Acid.png/25px-Icon_Sulfuric_Acid.png",
    gridIndex: new GridIndex(3, 5),

    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["refined oil"],
            quantity: 6,
          },
          {
            productId: ProductId["stone"],
            quantity: 8,
          },
          {
            productId: ProductId["water"],
            quantity: 4,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["refined oil"],
    img: "https://dsp-wiki.com/images/thumb/f/f9/Icon_Refined_Oil.png/25px-Icon_Refined_Oil.png",
    production: [
      {
        factoryType: FactoryType["refining facility"],
        materials: [
          {
            productId: ProductId["crude oil"],
            quantity: 2,
          },
          {
            productId: ProductId["plasma refining"],
            quantity: 0,
          },
        ],
        rates: [15],
      },
    ],
  },
  {
    id: ProductId["hydrogen fuel rod"],
    img: "https://dsp-wiki.com/images/thumb/2/2d/Icon_Hydrogen_Fuel_Rod.png/44px-Icon_Hydrogen_Fuel_Rod.png",
    gridIndex: new GridIndex(1, 9),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium ingot"],
            quantity: 1,
          },
          {
            productId: ProductId["hydrogen"],
            quantity: 5,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["deuteron fuel rod"],
    img: "https://dsp-wiki.com/images/thumb/5/58/Icon_Deuteron_Fuel_Rod.png/44px-Icon_Deuteron_Fuel_Rod.png",
    gridIndex: new GridIndex(1, 10),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 1,
          },
          {
            productId: ProductId["deuterium"],
            quantity: 10,
          },
          {
            productId: ProductId["super-magnetic ring"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["antimatter fuel rod"],
    img: "https://dsp-wiki.com/images/thumb/1/1c/Icon_Antimatter_Fuel_Rod.png/44px-Icon_Antimatter_Fuel_Rod.png",
    gridIndex: new GridIndex(1, 11),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["antimatter"],
            quantity: 6,
          },
          {
            productId: ProductId["hydrogen"],
            quantity: 6,
          },
          {
            productId: ProductId["annihilation constraint sphere"],
            quantity: 1,
          },
          {
            productId: ProductId["titanium alloy"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["magnet"],
    img: "https://dsp-wiki.com/images/thumb/c/c6/Icon_Magnet.png/45px-Icon_Magnet.png",
    gridIndex: new GridIndex(1, 0),

    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["iron ore"],
            quantity: 1,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["magnetic coil"],
    img: "https://dsp-wiki.com/images/thumb/e/e5/Icon_Magnetic_Coil.png/45px-Icon_Magnetic_Coil.png",
    gridIndex: new GridIndex(1, 1),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["magnet"],
            quantity: 2,
          },
          {
            productId: ProductId["copper ingot"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["glass"],
    img: "https://dsp-wiki.com/images/thumb/7/76/Icon_Glass.png/45px-Icon_Glass.png",
    gridIndex: new GridIndex(1, 4),
    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["stone"],
            quantity: 2,
          },
        ],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["diamond"],
    img: "https://dsp-wiki.com/images/thumb/a/af/Icon_Diamond.png/45px-Icon_Diamond.png",
    gridIndex: new GridIndex(1, 5),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["energetic graphite"],
            quantity: 1,
          },
        ],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["diamond alt"],
    img: "img/items/diamond-alt.png",
    gridIndex: new GridIndex(2, 5),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["kimberlite ore"],
            quantity: 1,
          },
        ],
        rates: [60],
      },
    ],
  },
  {
    id: ProductId["crystal silicon"],
    img: "https://dsp-wiki.com/images/thumb/2/2a/Icon_Crystal_Silicon.png/45px-Icon_Crystal_Silicon.png",
    gridIndex: new GridIndex(1, 2),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["high-purity silicon"],
            quantity: 1,
          },
        ],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["crystal silicon alt"],
    img: "img/items/crystal-silicon-alt.png",
    gridIndex: new GridIndex(2, 2),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["fractal silicon"],
            quantity: 1,
          },
        ],
        rates: [30],
      },
    ],
  },
  {
    id: ProductId["titanium alloy"],
    img: "https://dsp-wiki.com/images/thumb/8/85/Icon_Titanium_Alloy.png/45px-Icon_Titanium_Alloy.png",
    gridIndex: new GridIndex(1, 3),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["titanium ingot"],
            quantity: 4,
          },
          {
            productId: ProductId["steel"],
            quantity: 4,
          },
          {
            productId: ProductId["sulfuric acid"],
            quantity: 8,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["plastic"],
    img: "https://dsp-wiki.com/images/thumb/c/c8/Icon_Plastic.png/45px-Icon_Plastic.png",
    gridIndex: new GridIndex(0, 7),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["refined oil"],
            quantity: 2,
          },
          {
            productId: ProductId["energetic graphite"],
            quantity: 1,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["organic crystal"],
    img: "https://dsp-wiki.com/images/thumb/9/96/Icon_Organic_Crystal.png/30px-Icon_Organic_Crystal.png",
    gridIndex: new GridIndex(1, 7),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["plastic"],
            quantity: 2,
          },
          {
            productId: ProductId["refined oil"],
            quantity: 1,
          },
          {
            productId: ProductId["water"],
            quantity: 1,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["organic crystal alt"],
    img: "img/items/organic-crystal-alt.png",
    gridIndex: new GridIndex(2, 7),

    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["log"],
            quantity: 20,
          },
          {
            productId: ProductId["plant fuel"],
            quantity: 30,
          },
          {
            productId: ProductId["water"],
            quantity: 10,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["graphene"],
    img: "https://dsp-wiki.com/images/thumb/a/af/Icon_Graphene.png/45px-Icon_Graphene.png",
    gridIndex: new GridIndex(0, 8),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["energetic graphite"],
            quantity: 3,
          },
          {
            productId: ProductId["sulfuric acid"],
            quantity: 1,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["graphene alt"],
    img: "img/items/graphene-alt.png",
    gridIndex: new GridIndex(1, 8),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["fire ice"],
            quantity: 2,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["thruster"],
    img: "https://dsp-wiki.com/images/thumb/e/ea/Icon_Thruster.png/45px-Icon_Thruster.png",
    gridIndex: new GridIndex(2, 9),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["steel"],
            quantity: 2,
          },
          {
            productId: ProductId["copper ingot"],
            quantity: 3,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["steel"],
    img: "https://dsp-wiki.com/images/thumb/3/38/Icon_Steel.png/45px-Icon_Steel.png",
    gridIndex: new GridIndex(2, 0),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["smelting facility"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 3,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["circuit board"],
    img: "https://dsp-wiki.com/images/thumb/2/2f/Icon_Circuit_Board.png/45px-Icon_Circuit_Board.png",
    gridIndex: new GridIndex(3, 3),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 2,
          },
          {
            productId: ProductId["copper ingot"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["prism"],
    img: "https://dsp-wiki.com/images/thumb/d/d3/Icon_Prism.png/45px-Icon_Prism.png",
    gridIndex: new GridIndex(2, 4),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["glass"],
            quantity: 1.5,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["electric motor"],
    img: "https://dsp-wiki.com/images/thumb/8/84/Icon_Electric_Motor.png/45px-Icon_Electric_Motor.png",
    gridIndex: new GridIndex(2, 1),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 2,
          },
          {
            productId: ProductId["gear"],
            quantity: 1,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["microcrystalline component"],
    img: "https://dsp-wiki.com/images/thumb/f/f5/Icon_Microcrystalline_Component.png/45px-Icon_Microcrystalline_Component.png",
    gridIndex: new GridIndex(5, 2),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["high-purity silicon"],
            quantity: 2,
          },
          {
            productId: ProductId["copper ingot"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["casimir crystal"],
    img: "https://dsp-wiki.com/images/thumb/d/d7/Icon_Casimir_Crystal.png/45px-Icon_Casimir_Crystal.png",
    gridIndex: new GridIndex(4, 4),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium crystal"],
            quantity: 1,
          },
          {
            productId: ProductId["graphene"],
            quantity: 2,
          },
          {
            productId: ProductId["hydrogen"],
            quantity: 12,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["casimir crystal alt"],
    img: "img/items/casimir-crystal-alt.png",
    gridIndex: new GridIndex(5, 4),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["optical grating crystal"],
            quantity: 6,
          },
          {
            productId: ProductId["graphene"],
            quantity: 2,
          },
          {
            productId: ProductId["hydrogen"],
            quantity: 12,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["strange matter"],
    img: "https://dsp-wiki.com/images/thumb/8/8f/Icon_Strange_Matter.png/45px-Icon_Strange_Matter.png",
    gridIndex: new GridIndex(2, 11),

    production: [
      {
        factoryType: FactoryType["particle collider"],
        materials: [
          {
            productId: ProductId["particle container"],
            quantity: 2,
          },
          {
            productId: ProductId["iron ingot"],
            quantity: 2,
          },
          {
            productId: ProductId["deuterium"],
            quantity: 10,
          },
        ],
        rates: [],
      },
    ],
  },
  {
    id: ProductId["titanium crystal"],
    img: "https://dsp-wiki.com/images/thumb/5/5b/Icon_Titanium_Crystal.png/38px-Icon_Titanium_Crystal.png",
    gridIndex: new GridIndex(2, 8),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["organic crystal"],
            quantity: 1,
          },
          {
            productId: ProductId["titanium ingot"],
            quantity: 3,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["carbon nanotube"],
    img: "https://dsp-wiki.com/images/thumb/7/7c/Icon_Carbon_Nanotube.png/45px-Icon_Carbon_Nanotube.png",
    gridIndex: new GridIndex(3, 8),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["graphene"],
            quantity: 3,
          },
          {
            productId: ProductId["titanium ingot"],
            quantity: 1,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["carbon nanotube alt"],
    img: "img/items/carbon-nanotube-alt.png",
    gridIndex: new GridIndex(4, 8),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["chemical facility"],
        materials: [
          {
            productId: ProductId["spiniform stalagmite crystal"],
            quantity: 2,
          },
        ],
        rates: [40],
      },
    ],
  },
  {
    id: ProductId["reinforced thruster"],
    img: "https://dsp-wiki.com/images/thumb/3/3d/Icon_Reinforced_Thruster.png/45px-Icon_Reinforced_Thruster.png",
    gridIndex: new GridIndex(2, 10),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 5,
          },
          {
            productId: ProductId["electromagnetic turbine"],
            quantity: 5,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["gear"],
    img: "https://dsp-wiki.com/images/thumb/8/87/Icon_Gear.png/45px-Icon_Gear.png",
    gridIndex: new GridIndex(3, 0),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["plasma exciter"],
    img: "https://dsp-wiki.com/images/thumb/c/cc/Icon_Plasma_Exciter.png/45px-Icon_Plasma_Exciter.png",
    gridIndex: new GridIndex(4, 0),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["magnetic coil"],
            quantity: 4,
          },
          {
            productId: ProductId["prism"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["photon combiner"],
    img: "https://dsp-wiki.com/images/thumb/9/93/Icon_Photon_Combiner.png/45px-Icon_Photon_Combiner.png",
    gridIndex: new GridIndex(5, 0),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["prism"],
            quantity: 2,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["photon combiner alt"],
    img: "img/items/photon-combiner-alt.png",
    gridIndex: new GridIndex(5, 1),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["optical grating crystal"],
            quantity: 1,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["electromagnetic turbine"],
    img: "https://dsp-wiki.com/images/thumb/5/53/Icon_Electromagnetic_Turbine.png/45px-Icon_Electromagnetic_Turbine.png",
    gridIndex: new GridIndex(3, 1),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["electric motor"],
            quantity: 2,
          },
          {
            productId: ProductId["magnetic coil"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["processor"],
    img: "https://dsp-wiki.com/images/thumb/f/f1/Icon_Processor.png/45px-Icon_Processor.png",
    gridIndex: new GridIndex(4, 3),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["circuit board"],
            quantity: 2,
          },
          {
            productId: ProductId["microcrystalline component"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["annihilation constraint sphere"],
    img: "https://dsp-wiki.com/images/thumb/3/37/Icon_Annihilation_Constraint_Sphere.png/45px-Icon_Annihilation_Constraint_Sphere.png",
    gridIndex: new GridIndex(4, 7),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["particle container"],
            quantity: 1,
          },
          {
            productId: ProductId["processor"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["titanium glass"],
    img: "https://dsp-wiki.com/images/thumb/5/56/Icon_Titanium_Glass.png/44px-Icon_Titanium_Glass.png",
    gridIndex: new GridIndex(2, 3),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["glass"],
            quantity: 2,
          },
          {
            productId: ProductId["titanium ingot"],
            quantity: 2,
          },
          {
            productId: ProductId["water"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["particle broadband"],
    img: "https://dsp-wiki.com/images/thumb/b/b6/Icon_Particle_Broadband.png/45px-Icon_Particle_Broadband.png",
    gridIndex: new GridIndex(4, 2),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["carbon nanotube"],
            quantity: 2,
          },
          {
            productId: ProductId["crystal silicon"],
            quantity: 2,
          },
          {
            productId: ProductId["plastic"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["logistics drone"],
    img: "https://dsp-wiki.com/images/thumb/a/a2/Icon_Logistics_Drone.png/45px-Icon_Logistics_Drone.png",
    gridIndex: new GridIndex(3, 9),
    stack: 100,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["iron ingot"],
            quantity: 5,
          },
          {
            productId: ProductId["processor"],
            quantity: 2,
          },
          {
            productId: ProductId["thruster"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["foundation"],
    img: "https://dsp-wiki.com/images/thumb/6/60/Icon_Foundation.png/45px-Icon_Foundation.png",
    gridIndex: new GridIndex(6, 11),

    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["stone brick"],
            quantity: 3,
          },
          {
            productId: ProductId["steel"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["critical photon"],
    img: "https://dsp-wiki.com/images/thumb/9/92/Icon_Critical_Photon.png/45px-Icon_Critical_Photon.png",
    production: [
      {
        materials: [
          {
            productId: ProductId["ray receiver"],
            quantity: 0,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["particle container"],
    img: "https://dsp-wiki.com/images/thumb/f/fd/Icon_Particle_Container.png/45px-Icon_Particle_Container.png",
    gridIndex: new GridIndex(4, 5),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["electromagnetic turbine"],
            quantity: 2,
          },
          {
            productId: ProductId["copper ingot"],
            quantity: 2,
          },
          {
            productId: ProductId["graphene"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["particle container alt"],
    img: "img/items/particle-container-alt.png",
    gridIndex: new GridIndex(5, 5),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["unipolar magnet"],
            quantity: 10,
          },
          {
            productId: ProductId["copper ingot"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["super-magnetic ring"],
    img: "https://dsp-wiki.com/images/thumb/b/b4/Icon_Super-Magnetic_Ring.png/45px-Icon_Super-Magnetic_Ring.png",
    gridIndex: new GridIndex(4, 1),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["electromagnetic turbine"],
            quantity: 2,
          },
          {
            productId: ProductId["magnet"],
            quantity: 3,
          },
          {
            productId: ProductId["energetic graphite"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["graviton lens"],
    img: "https://dsp-wiki.com/images/thumb/d/df/Icon_Graviton_Lens.png/45px-Icon_Graviton_Lens.png",
    gridIndex: new GridIndex(3, 4),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["diamond"],
            quantity: 4,
          },
          {
            productId: ProductId["strange matter"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["space warper"],
    img: "https://dsp-wiki.com/images/thumb/c/cb/Icon_Space_Warper.png/45px-Icon_Space_Warper.png",
    gridIndex: new GridIndex(4, 6),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["graviton lens"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["space warper alt"],
    img: "img/items/space-warper-alt.png",
    gridIndex: new GridIndex(5, 6),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["gravity matrix"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["plane filter"],
    img: "https://dsp-wiki.com/images/thumb/1/15/Icon_Plane_Filter.png/45px-Icon_Plane_Filter.png",
    gridIndex: new GridIndex(3, 7),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["casimir crystal"],
            quantity: 1,
          },
          {
            productId: ProductId["titanium glass"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["quantum chip"],
    img: "https://dsp-wiki.com/images/thumb/2/2f/Icon_Quantum_Chip.png/45px-Icon_Quantum_Chip.png",
    gridIndex: new GridIndex(5, 3),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["processor"],
            quantity: 2,
          },
          {
            productId: ProductId["plane filter"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["logistics vessel"],
    img: "https://dsp-wiki.com/images/thumb/7/7e/Icon_Logistics_Vessel.png/44px-Icon_Logistics_Vessel.png",
    gridIndex: new GridIndex(3, 10),
    stack: 50,
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["titanium alloy"],
            quantity: 10,
          },
          {
            productId: ProductId["processor"],
            quantity: 10,
          },
          {
            productId: ProductId["reinforced thruster"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["electromagnetic matrix"],
    img: "https://dsp-wiki.com/images/thumb/0/0f/Icon_Electromagnetic_Matrix.png/45px-Icon_Electromagnetic_Matrix.png",
    gridIndex: new GridIndex(6, 0),
    production: [
      {
        factoryType: FactoryType["research facility"],
        materials: [
          {
            productId: ProductId["magnetic coil"],
            quantity: 1,
          },
          {
            productId: ProductId["circuit board"],
            quantity: 1,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["energy matrix"],
    img: "https://dsp-wiki.com/images/thumb/8/83/Icon_Energy_Matrix.png/45px-Icon_Energy_Matrix.png",
    gridIndex: new GridIndex(6, 1),
    production: [
      {
        factoryType: FactoryType["research facility"],
        materials: [
          {
            productId: ProductId["energetic graphite"],
            quantity: 2,
          },
          {
            productId: ProductId["hydrogen"],
            quantity: 2,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["structure matrix"],
    img: "https://dsp-wiki.com/images/thumb/4/44/Icon_Structure_Matrix.png/45px-Icon_Structure_Matrix.png",
    gridIndex: new GridIndex(6, 2),
    production: [
      {
        factoryType: FactoryType["research facility"],
        materials: [
          {
            productId: ProductId["diamond"],
            quantity: 1,
          },
          {
            productId: ProductId["titanium crystal"],
            quantity: 1,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["information matrix"],
    img: "https://dsp-wiki.com/images/thumb/c/ca/Icon_Information_Matrix.png/45px-Icon_Information_Matrix.png",
    gridIndex: new GridIndex(6, 3),
    production: [
      {
        factoryType: FactoryType["research facility"],
        materials: [
          {
            productId: ProductId["processor"],
            quantity: 2,
          },
          {
            productId: ProductId["particle broadband"],
            quantity: 1,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["gravity matrix"],
    img: "https://dsp-wiki.com/images/thumb/4/47/Icon_Gravity_Matrix.png/45px-Icon_Gravity_Matrix.png",
    gridIndex: new GridIndex(6, 4),
    production: [
      {
        factoryType: FactoryType["research facility"],
        materials: [
          {
            productId: ProductId["graviton lens"],
            quantity: 1,
          },
          {
            productId: ProductId["quantum chip"],
            quantity: 1,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["universe matrix"],
    img: "https://dsp-wiki.com/images/thumb/f/fc/Icon_Universe_Matrix.png/45px-Icon_Universe_Matrix.png",
    gridIndex: new GridIndex(6, 5),
    isFactoryRequired: true,
    production: [
      {
        factoryType: FactoryType["research facility"],
        materials: [
          {
            productId: ProductId["electromagnetic matrix"],
            quantity: 1,
          },
          {
            productId: ProductId["energy matrix"],
            quantity: 1,
          },
          {
            productId: ProductId["structure matrix"],
            quantity: 1,
          },
          {
            productId: ProductId["information matrix"],
            quantity: 1,
          },
          {
            productId: ProductId["gravity matrix"],
            quantity: 1,
          },
          {
            productId: ProductId["antimatter"],
            quantity: 1,
          },
        ],
        rates: [20],
      },
    ],
  },
  {
    id: ProductId["solar sail"],
    img: "https://dsp-wiki.com/images/thumb/5/50/Icon_Solar_Sail.png/45px-Icon_Solar_Sail.png",
    gridIndex: new GridIndex(4, 9),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["graphene"],
            quantity: 1,
          },
          {
            productId: ProductId["photon combiner"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["frame material"],
    img: "https://dsp-wiki.com/images/thumb/9/9d/Icon_Frame_Material.png/45px-Icon_Frame_Material.png",
    gridIndex: new GridIndex(4, 10),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["carbon nanotube"],
            quantity: 4,
          },
          {
            productId: ProductId["titanium alloy"],
            quantity: 1,
          },
          {
            productId: ProductId["high-purity silicon"],
            quantity: 1,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["dyson sphere component"],
    img: "https://dsp-wiki.com/images/thumb/c/ce/Icon_Dyson_Sphere_Component.png/45px-Icon_Dyson_Sphere_Component.png",
    gridIndex: new GridIndex(4, 11),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["frame material"],
            quantity: 3,
          },
          {
            productId: ProductId["solar sail"],
            quantity: 3,
          },
          {
            productId: ProductId["processor"],
            quantity: 3,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["small carrier rocket"],
    img: "https://dsp-wiki.com/images/thumb/1/1c/Icon_Small_Carrier_Rocket.png/45px-Icon_Small_Carrier_Rocket.png",
    gridIndex: new GridIndex(3, 11),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["dyson sphere component"],
            quantity: 2,
          },
          {
            productId: ProductId["deuteron fuel rod"],
            quantity: 2,
          },
          {
            productId: ProductId["quantum chip"],
            quantity: 2,
          },
        ],
        rates: [45, 60, 90],
      },
    ],
  },
  {
    id: ProductId["plasma refining"],
    img: "img/items/plasma-refining.png",
    gridIndex: new GridIndex(0, 6),
    isFactoryRequired: true,
    production: [],
  },
  {
    id: ProductId["deuterium fractionation"],
    img: "img/items/deuterium-fractionation.png",
    gridIndex: new GridIndex(2, 6),
    isFactoryRequired: true,
    production: [],
  },
  {
    id: ProductId["x ray cracking"],
    img: "img/items/x-ray-cracking.png",
    gridIndex: new GridIndex(1, 6),
    isFactoryRequired: true,
    production: [],
  },
  {
    id: ProductId["proliferator mk.i"],
    img: "img/items/proliferator.png",
    gridIndex: new GridIndex(0, 9),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["coal"],
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["proliferator mk.ii"],
    img: "img/items/proliferator2.png",
    gridIndex: new GridIndex(0, 10),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["proliferator mk.i"],
            quantity: 2,
          },
          {
            productId: ProductId["diamond"],
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["proliferator mk.iii"],
    img: "img/items/proliferator3.png",
    gridIndex: new GridIndex(0, 11),
    production: [
      {
        factoryType: FactoryType["assembler"],
        materials: [
          {
            productId: ProductId["proliferator mk.ii"],
            quantity: 2,
          },
          {
            productId: ProductId["carbon nanotube"],
            quantity: 1,
          },
        ],
      },
    ],
  },
  {
    id: ProductId["mass-energy storage"],
    img: "img/items/mass-energy-storage.png",
    gridIndex: new GridIndex(5, 7),
    isFactoryRequired: true,
    production: [],
  },
];
