import { useAuth } from "@gamate/common";
import { createContext, PropsWithChildren, useContext, useEffect, useState } from "react";
import { config } from "../../utils/config";
import { IPlanetService, PlanetService } from "../../utils/services/planetservice";
import { IProductService, ProductService } from "../../utils/services/productservice";
import { authentRequiredServiceFactory } from "../../utils/services/servicefactory";
import { IStarService, StarService } from "../../utils/services/starservice";

interface IProps extends PropsWithChildren<any> {
  starService?: IStarService;
  planetService?: IPlanetService;
  productService?: IProductService;
}

const ServiceContext = createContext({} as IProps);

export function ServiceProvider(props: IProps) {
  const [factory, setFactory] = useState(undefined as any);

  const { getAccessToken, isAuthenticated, isLoading } = useAuth(config.authTokenAudience);

  useEffect(() => {
    if (!isAuthenticated) return;
    if (isLoading) return;
    (async () => {
      const accessToken = await getAccessToken();
      setFactory(authentRequiredServiceFactory(accessToken));
    })();
  }, [isAuthenticated, isLoading, getAccessToken]);

  const value = {
    starService: props.starService || factory?.getInstance(StarService),
    planetService: props.planetService || factory?.getInstance(PlanetService),
    productService: props.productService || factory?.getInstance(ProductService),
  };

  return <ServiceContext.Provider value={value}>{props.children}</ServiceContext.Provider>;
}

export const useService = () => useContext(ServiceContext);
