import * as React from "react";
import styled from "styled-components";

import { IPlanet, IProduct } from "../../utils/interfaces";
import { productById } from "../../utils/data";
import { ProductId } from "../../utils/constants";
import { useService } from "./ServiceProvider";
import { usePageContext } from "./ContextProvider";
import ProductGrid from "../../components/ProductGrid";
import MaterialsManager from "./MaterialsManager";
import MaterialOnPlanetInfo from "./MaterialOnPlanetInfo";
import MaterialUsedIn from "./MaterialUsedIn";

const Container = styled.div``;

export default function PlanetOrganizer() {
  const [selectedProduct, setSelectedProduct] = React.useState(undefined as IProduct | undefined);
  const [planetProducts, setPlanetProducts] = React.useState([] as IProduct[]);
  const [planetsWithProduct, setPlanetsWithProduct] = React.useState([] as IPlanet[]);

  const { planetService, productService } = useService();
  const pageContext = usePageContext();

  const selectedIds = React.useMemo(() => {
    return planetProducts.map((p) => p.id).concat(selectedProduct !== undefined ? [selectedProduct.id] : []);
  }, [planetProducts, selectedProduct]);

  React.useEffect(() => {
    (async () => {
      if (pageContext.selectedPlanet?.id === undefined || productService === undefined) {
        setPlanetProducts([]);
        return;
      }
      const foundProducts = await productService.getAllFromPlanet(pageContext.selectedPlanet.id);
      setPlanetProducts(foundProducts);
    })();
  }, [pageContext.selectedPlanet, productService]);

  const handleProductClick = async (product: IProduct) => {
    setSelectedProduct(product);
    if (planetService === undefined) return;
    const planets = await planetService.getAllWithProduct(product.id);
    setPlanetsWithProduct(planets);
  };

  const handleAddProduct = async () => {
    if (selectedProduct === undefined) return;
    const selectedProductIndex = planetProducts.indexOf(selectedProduct);

    if (selectedProductIndex !== -1) return;

    addProduct(selectedProduct.id);
  };

  const handleRemoveProduct = () => {
    if (selectedProduct === undefined) return;
    const selectedProductIndex = planetProducts.indexOf(selectedProduct);

    if (selectedProductIndex === -1) return;

    removeProduct(selectedProductIndex);
  };

  const handleProductDblClick = (product: IProduct) => {
    if (pageContext.selectedPlanet === undefined || product === undefined) return;
    const clickedProductIndex = planetProducts.indexOf(product);
    if (clickedProductIndex !== -1) {
      removeProduct(clickedProductIndex);
    } else {
      addProduct(product.id);
    }
  };

  const addProduct = async (id: ProductId) => {
    const product = productById.get(id);
    if (pageContext.selectedPlanet?.id === undefined || product === undefined || productService === undefined) {
      return;
    }
    await productService.addToPlanet(pageContext.selectedPlanet.id, id);
    setPlanetProducts([...planetProducts, product]);

    if (planetService === undefined) return;
    const planets = await planetService.getAllWithProduct(id);
    setPlanetsWithProduct(planets);
  };

  const removeProduct = async (selectedProductIndex: number) => {
    if (pageContext.selectedPlanet?.id === undefined || productService === undefined) return;

    const newSelection = [...planetProducts];
    newSelection.splice(selectedProductIndex, 1);
    const productToRemove = planetProducts[selectedProductIndex];
    await productService.remove(pageContext.selectedPlanet.id, productToRemove.id);
    setPlanetProducts(newSelection);

    if (planetService === undefined) return;
    const planets = await planetService.getAllWithProduct(productToRemove.id);
    setPlanetsWithProduct(planets);
  };

  return (
    <>
      <Container>
        <div>
          <ProductGrid onClick={(product) => handleProductClick(product)} onDoubleClick={handleProductDblClick} selectedIds={selectedIds} />
        </div>
        <hr />
        <MaterialUsedIn selectedProduct={selectedProduct} />
        <MaterialOnPlanetInfo
          selectedProduct={selectedProduct}
          planetsWithProduct={planetsWithProduct}
          selectedPlanet={pageContext.selectedPlanet}
          planetProducts={planetProducts}
          onAddClick={handleAddProduct}
          onRemoveClick={handleRemoveProduct}
          onProductClick={handleProductClick}
          onProductDblClick={handleProductDblClick}
        />
        {selectedProduct !== undefined && (
          <>
            <MaterialsManager product={selectedProduct} />
          </>
        )}
      </Container>
    </>
  );
}
