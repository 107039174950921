import * as React from "react";
import styled from "styled-components";

interface IProps {
  icon: JSX.Element;
  label: string;
  quantity: number;
  stack?: number;

  onChange: React.ChangeEventHandler<HTMLInputElement>;
}

const StackContainer = styled.span`
  margin-left: 5px;
  cursor: help;
`;

export default function Product(props: IProps) {
  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div>
        <input
          style={{ width: "50px" }}
          type="number"
          value={props.quantity}
          min="1"
          onChange={props.onChange}
        />
      </div>
      <div style={{ padding: "0 5px" }}>{props.icon}</div>
      <div>
        {props.label}
        {props.stack ? <StackContainer title="stack count">[{props.stack}]</StackContainer> : ""}
      </div>
    </div>
  );
}
