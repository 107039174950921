import React from "react";
import ReactDOM from "react-dom";

import * as serviceWorker from "./serviceWorker";

import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import { Auth0Provider } from "@auth0/auth0-react";
import { config } from "./utils/config";

import "./index.css";
import "./utils/i18n";

ReactDOM.render(
  <Auth0Provider
    domain={config.AUTH_DOMAIN}
    clientId={config.AUTH_CLIENTID}
    redirectUri={window.location.origin}
    audience={config.authTokenAudience}
    scope={config.AUTH_SCOPE}
  >
    <Router>
      <App />
    </Router>
  </Auth0Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
