import * as React from "react";
import styled from "styled-components";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { IStar } from "../../../utils/interfaces";
import { useService } from "../ServiceProvider";

import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PlanetsManager from "../PlanetsManager";
import EditableLabel from "../../../components/EditableLabel";
import StarInfo from "./StarInfo";

interface IProps {
  star: any;
  isExpanded: boolean;
  onClick: (star: IStar) => void;
  onDelClick: (removedStarId: number) => void;
}

const SummaryContainer = styled.div`
  width: 100%;
  display: inline-flex;
`;

const DelIconContainer = styled.div`
  display: none;
  ${SummaryContainer}:hover & {
    display: inline;
  }
`;

export default function StarDisplay(props: IProps) {
  const [isEditingStar, setIsEditingStar] = React.useState(false);
  const [starName, setStarName] = React.useState(props.star.name);

  const { starService } = useService();

  const handleExpanding = (star: IStar) => {
    if (isEditingStar) return;
    props.onClick(star);
  };

  const handleUpdate = (input: string) => {
    if (input === undefined || input === "") return;

    starService?.updateStar({ id: props.star.id, name: input });
    setStarName(input);
    setIsEditingStar(false);
  };

  const handleDelClick = (event: any) => {
    const isYes = window.confirm("are you sure?");
    if (!isYes) {
      event.stopPropagation();
      return;
    }
    starService?.delete(props.star.id);
    props.onDelClick(props.star.id);
  };

  return (
    <Accordion expanded={props.isExpanded}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
        onClick={() => handleExpanding(props.star)}
      >
        <SummaryContainer>
          <EditableLabel
            text={starName}
            onValidation={handleUpdate}
            editButtonOnHover={true}
            onEdit={(isEditing) => setIsEditingStar(isEditing)}
          />
          <DelIconContainer onClick={handleDelClick}>x</DelIconContainer>
        </SummaryContainer>
      </AccordionSummary>
      <AccordionDetails>
        <div>
          <StarInfo star={props.star} />
          <PlanetsManager starId={props.star.id} />
        </div>
      </AccordionDetails>
    </Accordion>
  );
}
