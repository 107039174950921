import styled from "styled-components";
import { screen, ProductId } from "../../utils/constants";
import { romanNumber } from "../../utils/helpers";
import { IProduct } from "../../utils/interfaces";
import ProductIcon from "../ProductIcon";

interface IProps {
  elementByGridIndex: Map<number, IProduct>;
  filter?: ProductId[];
  selectedIds: ProductId[];

  onDoubleClick?: (selection: IProduct) => void;
  onClick?: (selection: IProduct) => void;
}

const Container = styled.div`
  @media (max-width: ${screen.desktop}) {
    overflow: scroll;
  }
`;

const Cell = styled.td<any>`
  cursor: pointer;
  width: 45px;
  height: 45px;
  background-color: ${(props) => (props.isFactoryRequired ? "#ca32327a" : "")};
  border: solid 1px;
  border-color: ${(props) => (props.isSelected ? "black" : "transparent")};
`;

const maxColumnCount = 12;
const maxRowCount = 7;

export default function Grid(props: IProps) {
  const rows = [];
  for (var row = 0; row < maxRowCount; row++) {
    const cells = [];
    for (var column = 0; column < maxColumnCount; column++) {
      const index = column + row * maxColumnCount;
      const product = props.elementByGridIndex.get(index);
      if (
        product === undefined ||
        (props.filter !== undefined && !props.filter.includes(product.id))
      ) {
        cells.push(<Cell key={index} />);
      } else {
        cells.push(
          <Cell
            key={index}
            onClick={() => {
              if (props.onClick) props.onClick(product);
            }}
            isFactoryRequired={product.isFactoryRequired}
            isSelected={props.selectedIds.includes(product.id)}
            onDoubleClick={() => {
              if (props.onDoubleClick) props.onDoubleClick(product);
            }}
          >
            <ProductIcon url={product.img} alt={ProductId[product.id].toString()} />
          </Cell>
        );
      }
    }
    rows.push(
      <tr key={row}>
        <td>{romanNumber(row + 1)}</td>
        {cells}
      </tr>
    );
  }

  return (
    <Container>
      <table>
        <tbody>{rows}</tbody>
      </table>
    </Container>
  );
}

Grid.defaultProps = {
  selectedIds: [],
};
