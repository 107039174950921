import { useEffect, useState } from "react";
import { ProductId, screen } from "../../utils/constants";
import {
  itemByGridOrder,
  buildingByGridOrder,
  resourceByGridOrder,
  items,
  buildings,
  resources,
} from "../../utils/data";
import { IProduct } from "../../utils/interfaces";
import { Tab, Tabs, useMediaQuery } from "@material-ui/core";

import Grid from "./Grid";
import Products from "../Products";

interface IProps {
  filter?: ProductId[];
  selectedIds: ProductId[];
  type: "building" | "item" | "resource";

  onClick?: (selection: IProduct) => void;
  onDoubleClick?: (selection: IProduct) => void;
}

export default function ProductGrid(props: IProps) {
  const [typeState, setType] = useState(props.type);
  const [gridProducts, setGridProducts] = useState(new Map());
  const [miscProducts, setMiscProducts] = useState([] as IProduct[]);
  const matches = useMediaQuery(`(max-width:${screen.desktop})`);

  useEffect(() => {
    switch (typeState) {
      case "building":
        setGridProducts(buildingByGridOrder);
        setMiscProducts(buildings.filter((p) => p.gridIndex === undefined));
        break;
      case "item":
        setGridProducts(itemByGridOrder);
        setMiscProducts(items.filter((p) => p.gridIndex === undefined));
        break;
      case "resource":
        setGridProducts(resourceByGridOrder);
        break;
    }
  }, [typeState]);

  return (
    <>
      <Tabs
        value={typeState}
        onChange={(e, value) => setType(value)}
        indicatorColor="primary"
        textColor="primary"
        variant={matches ? "fullWidth" : undefined}
      >
        <Tab label="Items" value={"item"} />
        <Tab label="Buildings" value={"building"} />
        <Tab label="Resources" value={"resource"} />
      </Tabs>
      {(typeState === "item" || typeState === "building") && (
        <>
          <Grid elementByGridIndex={gridProducts} {...props} />
          <Products elements={miscProducts} {...props} />
        </>
      )}
      {typeState === "resource" && (
        <Products
          elements={resources.filter((p) => p.gridIndex === undefined)}
          {...props}
        />
      )}
    </>
  );
}

ProductGrid.defaultProps = {
  type: "item",
  selectedIds: [],
};
