import { useEffect, useState } from "react";
import { ProductId } from "../../utils/constants";
import { products } from "../../utils/data";
import { IMaterial, IProduct } from "../../utils/interfaces";

import Material from "./Material";
import ProductIcon from "../../components/ProductIcon";
import Product from "./Product";

interface IProps {
  product: IProduct;
}

export default function MaterialsManager({ product }: IProps) {
  const [quantity, setQuantity] = useState(1);
  const [materials, setMaterials] = useState(new Map() as Map<ProductId, number>);

  useEffect(() => {
    const defaultMaterials = product.production[0]?.materials ?? [];
    setMaterials(
      new Map(
        defaultMaterials.map((m) => {
          return [m.productId, m.quantity];
        })
      )
    );
  }, [product]);

  const handleExpand = (clickedMaterial: ProductId, childMaterials: IMaterial[]) => {
    if (childMaterials.length === 0) return;
    const rootQty = materials.get(clickedMaterial);
    const newMaterials = new Map();
    const childMaterialsByProductId = new Map(
      childMaterials.map((m) => {
        return [m.productId, m.quantity];
      })
    );

    [...materials.entries()].forEach(([productId, materialQty]) => {
      if (productId === clickedMaterial) {
        childMaterialsByProductId.forEach((childQty, childProductId) => {
          const existingQty = materials.get(childProductId);
          newMaterials.set(childProductId, (rootQty ?? 0) * childQty + (existingQty ?? 0));
        });
        return;
      }
      newMaterials.set(productId, newMaterials.get(productId) ?? materialQty);
    });

    setMaterials(newMaterials);
  };

  const handleQtyChange = (e: any) => {
    setQuantity(parseInt(e.target.value));
  };

  return (
    <>
      <Product
        icon={<ProductIcon url={product.img} alt={ProductId[product.id].toString()} />}
        label={ProductId[product.id].toString()}
        quantity={quantity}
        stack={product.stack}
        onChange={handleQtyChange}
      />
      <div>
        <ul>
          {[...materials.entries()].map(([productId, matQty]) => {
            const product = products.filter((c) => c.id === productId)[0];
            if (product === undefined) return null;
            return (
              <li>
                <Material
                  label={ProductId[productId].toString()}
                  icon={<ProductIcon url={product.img} alt={ProductId[productId].toString()} />}
                  quantity={matQty * quantity}
                  onClick={() => {
                    handleExpand(productId, product.production[0]?.materials ?? []);
                  }}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </>
  );
}
