import { ProductId } from "./constants";
import { productById } from "./data";
import { IMaterial, IProduct } from "./interfaces";

export const componentHasMaterial = (component: IProduct, material?: ProductId) => {
  if (material === undefined)
    return component.production.findIndex((p) => p.materials !== undefined) !== -1;

  return (
    component.production.findIndex(
      (p) => p.materials !== undefined && materialsContains(p.materials, material)
    ) !== -1
  );
};

export const getMatchingProduction = (component: IProduct, material: ProductId) => {
  return component.production.filter(
    (p) => p.materials !== undefined && materialsContains(p.materials, material)
  )[0];
};

const materialsContains = (materials: IMaterial[], component: ProductId) => {
  return materials.findIndex((m) => m.productId === component) !== -1;
};

export const getMaterialRequiredFactoryCount = (
  product: IProduct,
  material: IMaterial,
  productFactoryCount: number
) => {
  const materialProduct = productById.get(material.productId);
  if (materialProduct === undefined) return null;

  const productRates = product?.production[0].rates;
  const materialRates = materialProduct?.production[0].rates;
  if (productRates === undefined || materialRates === undefined) return null;
  const productQty = productFactoryCount * productRates[0];
  const materialQty = productQty * material.quantity;
  return Math.ceil(materialQty / materialRates[0]);
};

export const romanNumber = (num: number) => {
  switch (num) {
    case 1:
      return "I";
    case 2:
      return "II";
    case 3:
      return "III";
    case 4:
      return "IV";
    case 5:
      return "V";
    case 6:
      return "VI";
    case 7:
      return "VII";
  }
  return "¯_(ツ)_/¯";
};
