const apiUrlHost = process.env.NODE_ENV !== "production" ? process.env.REACT_APP_API_URL_HOST : "";
const apiUrlRoot = `${apiUrlHost}/api`;
export const apiUrls = {
  planets: `${apiUrlRoot}/v0/planets`,
  planetproducts: `${apiUrlRoot}/v0/planetproducts`,
  products: `${apiUrlRoot}/v0/products`,
  stars: `${apiUrlRoot}/v0/stars`,
};

export const routes = {
  home: "/",
  efficiency: "/efficiency",
  decomposition: "/decomposition",
  destructuring: "/destructuring",
  info: "/info",
  organizer: "/organizer",
};
