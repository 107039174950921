export default function InformationPage() {
  return (
    <div>
      <div>
        <a href="https://dsp-wiki.com/Items">dsp-wiki.com</a>
      </div>
      <div>
        <a href="https://dyson-sphere-program.fandom.com/wiki/Dyson_Sphere_Program_Wiki">fandom</a>
      </div>
      <div>
        <a href="https://factoriolab.github.io/list?p=iron-ore*60&s=dsp&v=1">Calculator</a>
      </div>
    </div>
  );
}
