import { IStar } from "../interfaces";
import { apiUrls } from "../routes";
import { ServiceBase } from "./servicebase";

export interface IStarService {
  getAllStars: () => Promise<IStar[]>;
  addStar: (name: string, accessToken: string) => Promise<IStar>;
  updateStar: (star: IStar) => void;
  delete: (id: number) => void;
}

export class StarService extends ServiceBase implements IStarService {
  getAllStars = async () => {
    const result = await this.axiosInstance.get(apiUrls.stars);
    return result.data;
  };
  addStar = async (name: string): Promise<IStar> => {
    const result = await this.axiosInstance.post(apiUrls.stars, { name });
    return result.data;
  };
  updateStar = async (star: IStar) => {
    return await this.axiosInstance.put(apiUrls.stars, star);
  };
  delete = async (id: number) => {
    return await this.axiosInstance.delete(`${apiUrls.stars}/${id}`);
  };
}
