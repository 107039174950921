import { useAuth } from "@gamate/common";
import { config } from "../../utils/config";
import { IPlanet, IProduct } from "../../utils/interfaces";
import ProductIcon from "../../components/ProductIcon";
import { ProductId } from "../../utils/constants";
import PlanetDisplay from "./PlanetDisplay";

interface IProps {
  selectedProduct: IProduct | undefined;
  planetsWithProduct: IPlanet[];
  selectedPlanet: IPlanet | undefined;
  planetProducts: IProduct[];

  onAddClick: () => void;
  onRemoveClick: () => void;
  onProductClick: (product: IProduct) => void;
  onProductDblClick: (product: IProduct) => void;
}

export default function MaterialOnPlanetInfo({ selectedProduct, planetsWithProduct, selectedPlanet, planetProducts, onAddClick, onRemoveClick, onProductClick, onProductDblClick }: IProps) {
  const { isAuthenticated } = useAuth(config.authTokenAudience);

  if (!isAuthenticated) return null;

  return (
    <div id="MaterialOnPlanetInfo">
      {selectedProduct !== undefined && (
        <>
          <span>
            <ProductIcon url={selectedProduct.img} alt={ProductId[selectedProduct.id].toString()} />
            {ProductId[selectedProduct.id]} available on:{" "}
          </span>
          {planetsWithProduct.length === 0 && <>no planet</>}
          {planetsWithProduct.length > 0 && <>{planetsWithProduct.map((p) => p.name).join(", ")}</>}
          <hr />
        </>
      )}
      {selectedPlanet !== undefined && (
        <PlanetDisplay
          planet={selectedPlanet}
          planetProducts={planetProducts}
          selectedProduct={selectedProduct}
          enabledButton={selectedProduct && planetProducts.includes(selectedProduct) ? "remove" : "add"}
          onAddClick={onAddClick}
          onRemoveClick={onRemoveClick}
          onProductClick={onProductClick}
          onProductDblClick={onProductDblClick}
        />
      )}
    </div>
  );
}
