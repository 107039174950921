import * as React from "react";
import { useTranslation } from "react-i18next";

import { CircularProgress, TextField } from "@material-ui/core";
import { useAuth } from "@gamate/common";

import { IStar } from "../../utils/interfaces";
import { useService } from "./ServiceProvider";
import StarDisplay from "./StarDisplay";
import { config } from "../../utils/config";

export default function StarsManager() {
  const [stars, setStars] = React.useState([] as any[]);
  const [newStarInput, setNewStarInput] = React.useState("");
  const [isLoading, setIsLoading] = React.useState(false);

  const [expandedStarIds, setExpandedStarIds] = React.useState([] as number[]);

  const { starService } = useService();
  const { getAccessToken } = useAuth(config.authTokenAudience);
  const { t } = useTranslation();

  React.useEffect(() => {
    (async () => {
      setIsLoading(true);
      const stars = await starService?.getAllStars();
      if (stars === undefined) return;
      setStars(stars);

      setIsLoading(false);
    })();
  }, [starService]);

  const handleExpanding = (star: IStar) => {
    if (star.id === undefined) return;

    const index = expandedStarIds.indexOf(star.id);
    if (index === -1) {
      expandedStarIds.push(star.id);
    } else {
      expandedStarIds.splice(index, 1);
    }
    setExpandedStarIds([...expandedStarIds]);
  };

  const handleInputKeyPress = (e: any) => {
    if (e.key === "Enter") {
      addStar();
    }
  };

  const handleDelClick = (removedStarId: number) => {
    const newSet = stars.filter((star) => star.id !== removedStarId);
    setStars(newSet);
  };

  const addStar = async () => {
    if (newStarInput === undefined || newStarInput === "") return;

    const newStar = await starService?.addStar(newStarInput, await getAccessToken());
    if (newStar === undefined) return;

    setNewStarInput("");
    const newSet = [...stars, newStar];
    newSet.sort((a, b) => {
      return a.name > b.name ? 1 : -1;
    });
    setStars(newSet);
  };

  return (
    <>
      {isLoading && <CircularProgress />}
      {!isLoading && (
        <>
          <TextField
            value={newStarInput}
            onChange={(e) => setNewStarInput(e.target.value)}
            onKeyPress={handleInputKeyPress}
          />
          <button onClick={() => addStar()}>{t("dsp:label.addStar")}</button>
          {stars.map((star: any, index: number) => (
            <StarDisplay
              key={star.id}
              star={star}
              isExpanded={expandedStarIds.includes(star.id)}
              onClick={handleExpanding}
              onDelClick={handleDelClick}
            />
          ))}
        </>
      )}
    </>
  );
}
