import { ProductId } from "../constants";
import { products } from "../data";
import { IProduct } from "../interfaces";
import { apiUrls } from "../routes";
import { ServiceBase } from "./servicebase";

export interface IProductService {
  addToPlanet: (planetId: number, productId: ProductId) => Promise<any>;
  getAllFromPlanet: (planetId: number) => Promise<IProduct[]>;
  remove: (planetId: number, productId: ProductId) => void;
}

export class ProductService extends ServiceBase implements IProductService {
  addToPlanet = async (planetId: number, productId: ProductId) => {
    return this.axiosInstance.post(`${apiUrls.planetproducts}`, { planetId, productId });
  };

  remove = async (planetId: number, productId: ProductId) => {
    await this.axiosInstance.delete(`${apiUrls.planetproducts}/${productId}/${planetId}`);
  };

  getAllFromPlanet = async (planetId: number): Promise<IProduct[]> => {
    const result = await this.axiosInstance.get(`${apiUrls.planetproducts}/${planetId}`);
    const ids = result.data.map((p: any) => p.id);
    return products.filter((p) => ids.includes(p.id));
  };
}

export const productService = (accessToken: string) => new ProductService(accessToken);
